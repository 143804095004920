import { ActivityIndicator, Avatar, Button, Form, Input, Label, TextField } from '@troon/ui';
import { For, Suspense, Show, createSignal } from 'solid-js';
import { isServer } from 'solid-js/web';
import { createAsync } from '@solidjs/router';
import { useTrackEvent } from '@troon/analytics';
import { Icon } from '@troon/icons';
import { gql, mutationAction, useMutation } from '../graphql';
import { useUser } from '../providers/user';
import { cachedQuery } from '../graphql/cached-get';

type Props = {
	slots: Array<string>;
	reservationId: string;
	shareUrl: string;
};

export function InvitePlayers(props: Props) {
	const user = useUser();
	const users = createAsync(() => suggestedUsers({ reservationId: props.reservationId }));
	const trackEvent = useTrackEvent();
	const [urlCopied, setUrlCopied] = createSignal(false);
	const inviteUserAction = useMutation(handleInviteUser);

	const supportsShare = !isServer && typeof window !== 'undefined' && typeof window.navigator.share === 'function';
	return (
		<div class="flex flex-col gap-y-10">
			<p>
				You can invite up to {props.slots.length} player{props.slots.length !== 1 ? 's' : ''} to your tee time.
			</p>
			{supportsShare ? (
				<Button
					onClick={async () => {
						const trackData = { reservationId: props.reservationId, userId: user()!.me.id };
						try {
							await navigator.share({ title: 'Join this tee time', url: props.shareUrl, text: 'Join this tee time' });
							trackEvent('inviteShare', trackData);
						} catch (e) {
							// Ignore AbortErrors, they're expected if the user decides to not share
							if (!(e instanceof Error && e.name === 'AbortError')) {
								throw e;
							}
						}
					}}
				>
					<Icon name="share" />
					Invite a friend
				</Button>
			) : (
				<div>
					<div class="flex flex-row items-end">
						<TextField name="url" class="shrink-0 grow">
							<Label class="mb-4 text-xl font-semibold">Copy & share your unique link</Label>
							<Input
								readonly
								value={props.shareUrl}
								class="rounded-e-none"
								onClick={(event) => {
									event.currentTarget.select();
								}}
							/>
						</TextField>
						<div class="rounded-e-md border border-brand bg-brand">
							<Button
								type="button"
								onClick={() => {
									try {
										navigator.clipboard.writeText(props.shareUrl);
										setUrlCopied(true);
										setTimeout(() => {
											setUrlCopied(false);
										}, 10_000);
									} catch {
										// nothing
									}
								}}
								class="size-12 shrink grow-0 rounded-s-none p-0 text-lg"
							>
								<Icon name="copy" title="Copy URL" />
							</Button>
						</div>
					</div>
					<Show when={urlCopied()}>
						<p class="-mb-6 flex flex-row items-center justify-end gap-1 text-green-500">
							<Icon name="circle-check" />
							URL Copied to clipboard
						</p>
					</Show>
				</div>
			)}

			<div>
				<Suspense
					fallback={
						<div class="flex h-32 flex-row justify-center self-center">
							<ActivityIndicator />
						</div>
					}
				>
					<h3 class="mb-4 text-xl font-semibold">Recent players</h3>
					<ul class="flex flex-col divide-y divide-neutral-500">
						<For each={users()?.suggestedReservationUsers}>
							{(user) => (
								<li class="flex flex-wrap items-center gap-x-4 py-3">
									<Avatar
										firstName={user.firstName}
										lastName={user.lastName}
										class="size-12 rounded-full bg-brand text-brand-100"
									/>
									<span class="grow">
										{user.firstName} {user.lastName}
									</span>
									<Form action={inviteUserAction} document={inviteMutation} method="post">
										<input type="hidden" name="reservationId" value={props.reservationId} />
										<input type="hidden" name="reservationUserId" value={props.slots[0]} />
										<input type="hidden" name="userId" value={user.id} />
										<Button class="shrink grow-0" appearance="transparent" type="submit">
											Invite
										</Button>
									</Form>
								</li>
							)}
						</For>
					</ul>
				</Suspense>
			</div>
		</div>
	);
}

const suggestedQuery = gql(`
query suggestedReservationUsers($reservationId: String!) {
  suggestedReservationUsers(reservationId: $reservationId) {
    id
    email
    firstName
    lastName
  }
}`);

const suggestedUsers = cachedQuery(suggestedQuery);

const inviteMutation = gql(`
mutation inviteUserToReservation($reservationUserId: String!, $userId: String!) {
  inviteUserToReservation(reservationUserId: $reservationUserId, userId: $userId) {
    id
  }
}`);

const handleInviteUser = mutationAction(inviteMutation, {
	revalidates: ['reservationInformation', 'suggestedReservationUsers'],
	track: {
		event: 'inviteUser',
		transform(data) {
			return { reservationId: data.get('reservationId') as string, userId: data.get('userId') as string };
		},
	},
});
